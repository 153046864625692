import { createSlice } from '@reduxjs/toolkit'
import authActions from '../auth/actions'
import actions from './actions'

// onboardingStep === undefined means we havent done onboarding on this browser
// onboardingStep === null means we have completed onboarding on this browser
export interface OnboardingState {
  onboardingStep: 'coverage' | 'planning-categories' | 'template' | 'subscribe' | undefined | null
}

const initialState: OnboardingState = {
  onboardingStep: undefined,
}

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(authActions.loggedOut.type, () => {
        return initialState
      })
      .addCase(actions.setOnboardingStep, (state, action) => {
        state.onboardingStep = action.payload
      })
  },
})

export default onboardingSlice
