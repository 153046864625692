import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import templateReviewService from '../../../services/templateReviewService'
import { TemplatePendingReview } from '../../../models/TemplatePendingReview'

class TemplatesPendingReviewActions {
  loadTemplatesPendingReview = createAsyncThunk(
    'templatesPendingReview/loadTemplatesPendingReview',
    async (params, { dispatch }) => {
      const response = await templateReviewService.fetchTemplatesPendingReview({
        onReviewCompleted: (templatePendingReview: TemplatePendingReview) => {
          dispatch(this.removeTemplate(templatePendingReview))
        },
        onReviewRequested: (templatePendingReview: TemplatePendingReview) => {
          dispatch(this.addTemplate(templatePendingReview))
        },
      })
      return response
    },
  )
  addTemplate = createAction(
    'templatesPendingReview/addTemplate',
    (templatePendingReview: TemplatePendingReview) => ({
      payload: templatePendingReview,
    }),
  )
  removeTemplate = createAction(
    'templatesPendingReview/removeTemplate',
    (templatePendingReview: TemplatePendingReview) => ({
      payload: templatePendingReview,
    }),
  )
}

export default new TemplatesPendingReviewActions()
