import { createAction } from '@reduxjs/toolkit'
import { AuthFormUserResult } from '../../../models/AuthFormUserResult'
import { AuthToken } from '../../../models/AuthToken'

class AuthActions {
  loggedOut = createAction('auth/loggedOut', () => ({ payload: undefined }))
  verified = createAction('auth/verified', () => ({ payload: undefined }))
  loggedIn = createAction('auth/loggedIn', (payload: AuthFormUserResult) => ({
    payload,
  }))
  tokenUpdated = createAction(
    'auth/tokenUpdated',
    (payload: Required<Pick<AuthToken, 'companyId' | 'role'>>) => ({
      payload,
    }),
  )
}

export default new AuthActions()
