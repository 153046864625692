import { Middleware } from '@reduxjs/toolkit'
import authService from '../../../services/authService'

class AuthMiddleware {
  logoutMiddleware: Middleware = () => next => action => {
    next(action)
    if (action.type === 'auth/loggedOut') {
      authService.logout()
    }
  }
}

export default new AuthMiddleware()
