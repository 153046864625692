import { useRef, useCallback, DependencyList } from 'react'

// leading debounce - callback is called immediately and then a delay is applied afterwards to prevent spam
const useCallbackDebounced = <T extends (...args: any[]) => any>(
  fn: T,
  deps: DependencyList,
  delay = 300,
): T => {
  const timerRef = useRef<NodeJS.Timeout>()
  const resRef = useRef<ReturnType<T>>()
  const callback = (...args: Parameters<T>): ReturnType<T> => {
    if (!timerRef.current) {
      resRef.current = fn(...args)
    } else {
      clearTimeout(timerRef.current)
    }
    timerRef.current = setTimeout(() => {
      timerRef.current = undefined
    }, delay)
    return resRef.current as ReturnType<T>
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(callback, deps) as T
}

export default useCallbackDebounced
