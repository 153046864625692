import { doc, DocumentSnapshot, onSnapshot, Unsubscribe } from 'firebase/firestore'
import firestore from '../../firestore'

class UserAuthTriggerService {
  private cacheUserId: string | undefined = undefined
  private cacheVersion: number | undefined = undefined
  subscribeToUserAuthTriggerVersion = ({
    userId,
    onUserAuthTriggerIncremented,
  }: {
    userId: string
    onUserAuthTriggerIncremented: () => void
  }): Unsubscribe => {
    if (this.cacheUserId != userId) {
      this.cacheUserId = userId
      this.cacheVersion = undefined
    }
    const unsubscribeFromUserAuthTrigger = onSnapshot(
      doc(firestore.userAuthTriggers(), userId),
      (snapshot: DocumentSnapshot) => {
        if (!snapshot || !snapshot.exists()) {
          return
        }
        const version = snapshot.data()?.version
        if (this.cacheVersion === undefined && version !== undefined) {
          this.cacheVersion = version
          return
        }
        if (
          this.cacheVersion !== undefined &&
          version !== undefined &&
          version > this.cacheVersion
        ) {
          this.cacheVersion = version
          onUserAuthTriggerIncremented()
        }
      },
    )
    return () => {
      this.cacheUserId = undefined
      this.cacheVersion = undefined
      return unsubscribeFromUserAuthTrigger
    }
  }
}

export default new UserAuthTriggerService()
