import { useNavigate } from 'react-router-dom'
import HeaderLayout from './HeaderLayout'
import { useDispatch, useSelector } from '../../../redux/hooks'
import authActions from '../../../redux/modules/auth/actions'
import authSelectors from '../../../redux/modules/auth/selectors'
import useCallbackDebounced from '../../../hooks/shared/useCallbackDebounced'

const HeaderLayoutBase = () => {
  // const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(authSelectors.getLoggedIn)
  const email = useSelector(authSelectors.getEmail)
  const login = useCallbackDebounced(async () => {
    navigate('/login')
  }, [navigate])
  const logout = useCallbackDebounced(async () => {
    dispatch(authActions.loggedOut())
    navigate('/login')
  }, [dispatch, navigate])
  return <HeaderLayout login={login} logout={logout} isLoggedIn={isLoggedIn} email={email || ''} />
}

export default HeaderLayoutBase
