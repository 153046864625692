import { useEffect } from 'react'
import { Unsubscribe } from 'firebase/firestore'
import { useDispatch, useSelector } from '../../redux/hooks'
import companyActions from '../../redux/modules/company/actions'
import authSelectors from '../../redux/modules/auth/selectors'
import { useIsMountedRef } from '../shared/useIsMountedRef'

export const useSubscribeToCompany = () => {
  const isMountedRef = useIsMountedRef()
  const dispatch = useDispatch()
  const companyId = useSelector(authSelectors.getCompanyId)
  useEffect(() => {
    let unsubscribe: Unsubscribe
    if (companyId) {
      dispatch(companyActions.loadCompany({ companyId }))
        .unwrap()
        .then(({ unsubscribe: unsubscribeFromCompany }) => {
          if (isMountedRef.current === false) {
            unsubscribeFromCompany()
          } else {
            unsubscribe = unsubscribeFromCompany
          }
        })
    }
    return () => {
      if (unsubscribe) {
        unsubscribe()
      }
    }
  }, [companyId, dispatch, isMountedRef])
}
