import { createSlice } from '@reduxjs/toolkit'
import { PeriodReport } from '../../../models/PeriodReport'
import authActions from '../auth/actions'
import actions from './actions'

export interface DashboardState {
  today?: PeriodReport
  yesterday?: PeriodReport
  thisMonth?: PeriodReport
  lastMonth?: PeriodReport
  allTime?: PeriodReport
  currentRequest?: string
  error?: string
  status: 'idle' | 'loading' | 'failed'
}

const initialState: DashboardState = {
  today: undefined,
  yesterday: undefined,
  thisMonth: undefined,
  lastMonth: undefined,
  allTime: undefined,
  status: 'idle',
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(authActions.loggedOut.type, () => {
        return initialState
      })
      .addCase(actions.loadDashboard.pending, (state, action) => {
        state.currentRequest = action.meta.requestId
        state.status = 'loading'
      })
      .addCase(actions.loadDashboard.fulfilled, (state, action) => {
        if (state.currentRequest === action.meta.requestId) {
          state.status = 'idle'
          state.today = action.payload.data.today
          state.yesterday = action.payload.data.yesterday
          state.thisMonth = action.payload.data.thisMonth
          state.lastMonth = action.payload.data.lastMonth
          state.allTime = action.payload.data.allTime
          state.error = undefined
        } else {
          action.payload.unsubscribe()
        }
      })
      .addCase(actions.loadDashboard.rejected, (state, action) => {
        if (state.currentRequest === action.meta.requestId) {
          state.status = 'failed'
          state.error = action.error.message
        }
      })
      .addCase(actions.updateAllTimePeriodReport, (state, action) => {
        state.allTime = action.payload
      })
      .addCase(actions.updateLastMonthPeriodReport, (state, action) => {
        state.lastMonth = action.payload
      })
      .addCase(actions.updateThisMonthPeriodReport, (state, action) => {
        state.thisMonth = action.payload
      })
      .addCase(actions.updateTodayPeriodReport, (state, action) => {
        state.today = action.payload
      })
      .addCase(actions.updateYesterdayPeriodReport, (state, action) => {
        state.yesterday = action.payload
      })
  },
})

export default dashboardSlice
