import { query, where, documentId } from 'firebase/firestore'
import firestore from '../../firestore'
import { PeriodReport } from '../../models/PeriodReport'
import BaseService from '../BaseService'
import { CompanyRankReport } from '../../models/CompanyRankReport'
import _ from 'lodash'
import { Company } from '../../models/Company'
import { CompanyReportInfo } from '../../models/CompanyReportInfo'
import { PendingDeadLetterCounter } from '../../models/PendingDeadLetterCounter'

class DashboardService extends BaseService {
  private getReportIds = () => {
    const now = new Date()
    const yesterdayDate = new Date(now)
    yesterdayDate.setHours(-1)

    const monthNumber = now.getMonth() + 1
    const month = String(monthNumber).length === 1 ? `0${monthNumber}` : `${monthNumber}`
    const year = now.getFullYear().toString()
    const lastYear = (now.getFullYear() - 1).toString()

    const lastMonthNumber = monthNumber === 1 ? 12 : monthNumber - 1
    const lastMonth =
      String(lastMonthNumber).length === 1 ? `0${lastMonthNumber}` : `${lastMonthNumber}`
    const yearLastMonth = lastMonthNumber > monthNumber ? (now.getFullYear() - 1).toString() : year

    const today = now.getDate().toString().length === 1 ? `0${now.getDate()}` : `${now.getDate()}`
    const yesterday =
      yesterdayDate.getDate().toString().length === 1
        ? `0${yesterdayDate.getDate()}`
        : `${yesterdayDate.getDate()}`

    const calendarMonthYesterday = yesterdayDate.getMonth() + 1
    const monthYesterday =
      String(calendarMonthYesterday).length === 1
        ? `0${calendarMonthYesterday}`
        : `${calendarMonthYesterday}`
    const yearYesterday = yesterdayDate.getFullYear().toString()

    const thisMonthReportId = `MONTHLY_${year}_${month}`
    const lastMonthReportId = `MONTHLY_${yearLastMonth}_${lastMonth}`

    const todayReportId = `DAILY_${year}_${month}_${today}`
    const yesterdayReportId = `DAILY_${yearYesterday}_${monthYesterday}_${yesterday}`

    const thisYearReportId = `YEARLY_${year}`
    const lastYearReportId = `YEARLY_${lastYear}`
    return {
      thisMonthReportId,
      lastMonthReportId,
      todayReportId,
      yesterdayReportId,
      thisYearReportId,
      lastYearReportId,
      allTimeReportId: 'ALL_TIME',
    }
  }
  fetchCompanyDashboardData = async ({
    companyId,
    onReportingRecordUpdated,
    onReportingRecordAdded,
  }: {
    companyId: string
    onReportingRecordUpdated: (report: {
      thisMonth?: PeriodReport
      lastMonth?: PeriodReport
      today?: PeriodReport
      yesterday?: PeriodReport
      allTime?: PeriodReport
    }) => void
    onReportingRecordAdded: (report: {
      thisMonth?: PeriodReport
      lastMonth?: PeriodReport
      today?: PeriodReport
      yesterday?: PeriodReport
      allTime?: PeriodReport
    }) => void
  }) => {
    const {
      thisMonthReportId,
      lastMonthReportId,
      todayReportId,
      yesterdayReportId,
      allTimeReportId,
    } = this.getReportIds()
    const { data, unsubscribe } = await this.onSnapshot({
      query: query(
        firestore.companyReports(companyId),
        where(documentId(), 'in', [
          allTimeReportId,
          thisMonthReportId,
          lastMonthReportId,
          todayReportId,
          yesterdayReportId,
        ]),
      ),
      onUpdated: (report: PeriodReport) => {
        switch (report.id) {
          case thisMonthReportId:
            onReportingRecordUpdated({
              thisMonth: report,
            })
            break
          case lastMonthReportId:
            onReportingRecordUpdated({
              lastMonth: report,
            })
            break
          case todayReportId:
            onReportingRecordUpdated({
              today: report,
            })
            break
          case yesterdayReportId:
            onReportingRecordUpdated({
              yesterday: report,
            })
            break
          case allTimeReportId:
            onReportingRecordUpdated({
              allTime: report,
            })
            break
        }
      },
      onAdded: (report: PeriodReport) => {
        switch (report.id) {
          case thisMonthReportId:
            onReportingRecordAdded({
              thisMonth: report,
            })
            break
          case lastMonthReportId:
            onReportingRecordAdded({
              lastMonth: report,
            })
            break
          case todayReportId:
            onReportingRecordAdded({
              today: report,
            })
            break
          case yesterdayReportId:
            onReportingRecordAdded({
              yesterday: report,
            })
            break
          case allTimeReportId:
            onReportingRecordAdded({
              allTime: report,
            })
            break
        }
      },
    })
    const dashboardData = data.reduce<{
      thisMonth: PeriodReport
      lastMonth: PeriodReport
      today: PeriodReport
      yesterday: PeriodReport
      allTime: PeriodReport
    }>(
      (memo, next) => {
        switch (next.id) {
          case thisMonthReportId:
            memo.thisMonth = next
            break
          case lastMonthReportId:
            memo.lastMonth = next
            break
          case todayReportId:
            memo.today = next
            break
          case yesterdayReportId:
            memo.yesterday = next
            break
          case allTimeReportId:
            memo.allTime = next
            break
        }
        return memo
      },
      {
        thisMonth: { id: thisMonthReportId, totalLettersSent: 0 },
        lastMonth: { id: lastMonthReportId, totalLettersSent: 0 },
        today: { id: todayReportId, totalLettersSent: 0 },
        yesterday: { id: yesterdayReportId, totalLettersSent: 0 },
        allTime: { id: allTimeReportId, totalLettersSent: 0 },
      },
    )
    return {
      data: dashboardData,
      unsubscribe,
    }
  }
  fetchSuperAdminDashboardData = async ({
    onReportingRecordUpdated,
    onReportingRecordAdded,
  }: {
    onReportingRecordUpdated: (report: {
      thisMonth?: PeriodReport
      lastMonth?: PeriodReport
      today?: PeriodReport
      yesterday?: PeriodReport
      allTime?: PeriodReport
    }) => void
    onReportingRecordAdded: (report: {
      thisMonth?: PeriodReport
      lastMonth?: PeriodReport
      today?: PeriodReport
      yesterday?: PeriodReport
      allTime?: PeriodReport
    }) => void
  }) => {
    const {
      thisMonthReportId,
      lastMonthReportId,
      todayReportId,
      yesterdayReportId,
      allTimeReportId,
    } = this.getReportIds()
    const { data, unsubscribe } = await this.onSnapshot({
      query: query(
        firestore.reports(),
        where(documentId(), 'in', [
          allTimeReportId,
          thisMonthReportId,
          lastMonthReportId,
          todayReportId,
          yesterdayReportId,
        ]),
      ),
      onUpdated: (report: PeriodReport) => {
        switch (report.id) {
          case thisMonthReportId:
            onReportingRecordUpdated({
              thisMonth: report,
            })
            break
          case lastMonthReportId:
            onReportingRecordUpdated({
              lastMonth: report,
            })
            break
          case todayReportId:
            onReportingRecordUpdated({
              today: report,
            })
            break
          case yesterdayReportId:
            onReportingRecordUpdated({
              yesterday: report,
            })
            break
          case allTimeReportId:
            onReportingRecordUpdated({
              allTime: report,
            })
            break
        }
      },
      onAdded: (report: PeriodReport) => {
        switch (report.id) {
          case thisMonthReportId:
            onReportingRecordAdded({
              thisMonth: report,
            })
            break
          case lastMonthReportId:
            onReportingRecordAdded({
              lastMonth: report,
            })
            break
          case todayReportId:
            onReportingRecordAdded({
              today: report,
            })
            break
          case yesterdayReportId:
            onReportingRecordAdded({
              yesterday: report,
            })
            break
          case allTimeReportId:
            onReportingRecordAdded({
              allTime: report,
            })
            break
        }
      },
    })
    const dashboardData = data.reduce<{
      thisMonth: PeriodReport
      lastMonth: PeriodReport
      today: PeriodReport
      yesterday: PeriodReport
      allTime: PeriodReport
    }>(
      (memo, next) => {
        switch (next.id) {
          case thisMonthReportId:
            memo.thisMonth = next
            break
          case lastMonthReportId:
            memo.lastMonth = next
            break
          case todayReportId:
            memo.today = next
            break
          case yesterdayReportId:
            memo.yesterday = next
            break
          case allTimeReportId:
            memo.allTime = next
            break
        }
        return memo
      },
      {
        thisMonth: { id: thisMonthReportId, totalLettersSent: 0 },
        lastMonth: { id: lastMonthReportId, totalLettersSent: 0 },
        today: { id: todayReportId, totalLettersSent: 0 },
        yesterday: { id: yesterdayReportId, totalLettersSent: 0 },
        allTime: { id: allTimeReportId, totalLettersSent: 0 },
      },
    )
    return {
      data: dashboardData,
      unsubscribe,
    }
  }
  private processCompanyRankReport = (companyRankReport: CompanyRankReport) => {
    return _.chain(companyRankReport)
      .omit('id')
      .entries()
      .orderBy(entry => {
        const [, numberOfLettersSent] = entry
        return numberOfLettersSent
      }, 'desc')
      .take(15)
      .reduce<CompanyRankReport>(
        (memo, next) => {
          const [companyId, numberOfLettersSent] = next
          memo[companyId] = numberOfLettersSent
          return memo
        },
        { id: companyRankReport.id } as CompanyRankReport,
      )
      .value()
  }
  fetchSuperAdminCustomerRankingData = async ({
    onReportingRecordUpdated,
    onReportingRecordAdded,
  }: {
    onReportingRecordUpdated: (report: {
      thisMonth?: CompanyRankReport
      lastMonth?: CompanyRankReport
      allTime?: CompanyRankReport
    }) => void
    onReportingRecordAdded: (report: {
      thisMonth?: CompanyRankReport
      lastMonth?: CompanyRankReport
      allTime?: CompanyRankReport
    }) => void
  }) => {
    const { thisMonthReportId, lastMonthReportId, allTimeReportId } = this.getReportIds()
    const { data, unsubscribe } = await this.onSnapshot({
      query: query(
        firestore.companyRankReports(),
        where(documentId(), 'in', [allTimeReportId, thisMonthReportId, lastMonthReportId]),
      ),
      onUpdated: (report: CompanyRankReport) => {
        switch (report.id) {
          case thisMonthReportId:
            onReportingRecordUpdated({
              thisMonth: this.processCompanyRankReport(report),
            })
            break
          case lastMonthReportId:
            onReportingRecordUpdated({
              lastMonth: this.processCompanyRankReport(report),
            })
            break
          case allTimeReportId:
            onReportingRecordUpdated({
              allTime: this.processCompanyRankReport(report),
            })
            break
        }
      },
      onAdded: (report: CompanyRankReport) => {
        switch (report.id) {
          case thisMonthReportId:
            onReportingRecordAdded({
              thisMonth: this.processCompanyRankReport(report),
            })
            break
          case lastMonthReportId:
            onReportingRecordAdded({
              lastMonth: this.processCompanyRankReport(report),
            })
            break
          case allTimeReportId:
            onReportingRecordAdded({
              allTime: this.processCompanyRankReport(report),
            })
            break
        }
      },
    })
    const customerRankingData = data.reduce<{
      thisMonth: CompanyRankReport
      lastMonth: CompanyRankReport
      allTime: CompanyRankReport
    }>(
      (memo, next) => {
        switch (next.id) {
          case thisMonthReportId:
            memo.thisMonth = this.processCompanyRankReport(next)
            break
          case lastMonthReportId:
            memo.lastMonth = this.processCompanyRankReport(next)
            break
          case allTimeReportId:
            memo.allTime = this.processCompanyRankReport(next)
            break
        }
        return memo
      },
      {
        thisMonth: { id: thisMonthReportId } as CompanyRankReport,
        lastMonth: { id: lastMonthReportId } as CompanyRankReport,
        allTime: { id: allTimeReportId } as CompanyRankReport,
      },
    )
    return {
      data: customerRankingData,
      unsubscribe,
    }
  }
  fetchSuperAdminCompanyReportInfo = async ({
    onCompanyReportInfoUpdated,
  }: {
    onCompanyReportInfoUpdated: (company: CompanyReportInfo) => void
  }) => {
    const { data, unsubscribe } = await this.onSnapshot({
      query: firestore.companies(),
      onUpdated: (company: Company) => {
        onCompanyReportInfoUpdated(
          _.pick(company, ['id', 'defaultTemplateId', 'defaultTemplateVersion', 'accountEmail']),
        )
      },
    })
    const companyInfo = data.map(company =>
      _.pick(company, ['id', 'defaultTemplateId', 'defaultTemplateVersion', 'accountEmail']),
    )
    return {
      data: companyInfo,
      unsubscribe,
    }
  }

  private basePendingDeadLetterCounter: PendingDeadLetterCounter = {
    id: 'PENDING_DEAD_LETTER_COUNTER',
    chargeCompanyOnSubscriptionWithdrawalRequested: 0,
    createStripeCustomerOnUserRegistered: 0,
    createTemplatePreviewOnTemplateFileSaved: 0,
    emailAccountOnCreditsDepleted: 0,
    emailReceiptOnPaymentReceived: 0,
    emailUpdateOnPaymentMethodChanged: 0,
    publishToCompaniesOnPlanningRecordApproved: 0,
    scrapePageOnLpaDetailsPageScrapeRequested: 0,
    scrapePageOnLpaSearchPageScrapeRequested: 0,
    sendEmailOnTemplateApproved: 0,
    sendEmailOnTemplateDeclined: 0,
    sendMailOnPlanningRecordAssignedToCompany: 0,
    sendMailToRecentOnMailBackfillRequested: 0,
    sendVerificationEmailOnUserRegistered: 0,
    updateCompanyOnPaymentMethodChanged: 0,
    updateCompanyOnPaymentReceived: 0,
    updateMailStatusOnMailStatusSyncRequested: 0,
    updatePlanningRecordOnPredictionCheckRequested: 0,
  }

  fetchPendingDeadLetterCounter = async ({
    onPendingDeadLetterCounterUpdated,
  }: {
    onPendingDeadLetterCounterUpdated: (pendingDeadLetterCounter: PendingDeadLetterCounter) => void
  }) => {
    const { data, unsubscribe } = await this.onDoc({
      ref: firestore.pendingDeadLetterCounter(),
      onUpdated: (pendingDeadLetterCounter: PendingDeadLetterCounter) => {
        onPendingDeadLetterCounterUpdated(<PendingDeadLetterCounter>{
          ...this.basePendingDeadLetterCounter,
          ...pendingDeadLetterCounter,
        })
      },
    })
    if (data == null) {
      return {
        data: this.basePendingDeadLetterCounter,
        unsubscribe,
      }
    }
    return {
      data: <PendingDeadLetterCounter>{
        ...this.basePendingDeadLetterCounter,
        ...data,
      },
      unsubscribe,
    }
  }
}

export default new DashboardService()
