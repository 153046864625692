import { useEffect, ReactElement, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import authSelectors from '../../../redux/modules/auth/selectors'
import { store } from '../../../redux/store'

const PublicRoute = ({ children }: { children: ReactElement }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const loggedIn = useMemo(() => {
    return authSelectors.getLoggedIn(store.getState())
  }, [])
  const emailVerified = useMemo(() => {
    return authSelectors.getEmailVerified(store.getState())
  }, [])
  useEffect(() => {
    if (loggedIn && !emailVerified) {
      console.info('>>> PublicRoute redirect to /await-email-verification')
      const fallbackPathname =
        location.pathname === '/login' ||
        location.pathname === '/token-login' ||
        location.pathname === '/reset-password' ||
        location.pathname === '/reset-password-success' ||
        location.pathname === '/register'
          ? '/'
          : location.pathname
      navigate('/await-email-verification', {
        state: {
          resendEmailPromptDelayed: location.state?.resendEmailPromptDelayed,
          from: location.state?.from || fallbackPathname,
        },
        replace: true,
      })
      return
    }
  }, [
    emailVerified,
    location.pathname,
    location.state?.from,
    location.state?.resendEmailPromptDelayed,
    loggedIn,
    navigate,
  ])
  return children
}

export default PublicRoute
