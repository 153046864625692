import { useEffect } from 'react'
import { Unsubscribe } from 'firebase/firestore'
import { useSelector, useDispatch } from '../../redux/hooks'
import authSelectors from '../../redux/modules/auth/selectors'
import authActions from '../../redux/modules/auth/actions'
import userAuthTriggerService from '../../services/userAuthTriggerService'
import authService from '../../services/authService'

export const useRefreshAuthTokenOnClaimsChange = () => {
  const dispatch = useDispatch()
  const userId = useSelector(authSelectors.getUserId)
  useEffect(() => {
    let unsubscribe: Unsubscribe
    if (userId) {
      unsubscribe = userAuthTriggerService.subscribeToUserAuthTriggerVersion({
        userId,
        onUserAuthTriggerIncremented: async () => {
          const token = await authService.refreshToken()
          if (token?.companyId && token?.role) {
            dispatch(authActions.tokenUpdated({ companyId: token.companyId, role: token.role }))
          }
        },
      })
    }
    return () => {
      if (unsubscribe) {
        unsubscribe()
      }
    }
  }, [dispatch, userId])
}
