import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import planningRecordService from '../../../services/planningRecordService'
import { PlanningRecord } from '../../../models/PlanningRecord'

class PlanningRecordsActions {
  loadPlanningRecordsFresh = createAsyncThunk(
    'planningRecords/loadPlanningRecordsFresh',
    async (params, { dispatch }) => {
      const response = await planningRecordService.fetchPaginatedPlanningRecords({
        forceFirstPage: true,
        onPlanningRecordUpdated: planningRecord => {
          dispatch(this.updatePlanningRecord(planningRecord))
        },
      })
      return response
    },
  )
  loadPlanningRecordsNext = createAsyncThunk(
    'planningRecords/loadPlanningRecordsNext',
    async (params, { dispatch }) => {
      const response = await planningRecordService.fetchPaginatedPlanningRecords({
        onPlanningRecordUpdated: planningRecord => {
          dispatch(this.updatePlanningRecord(planningRecord))
        },
      })
      return response
    },
  )
  updatePlanningRecord = createAction(
    'planningRecords/updatePlanningRecord',
    (planningRecord: PlanningRecord) => ({
      payload: planningRecord,
    }),
  )
  approvePlanningRecord = createAction(
    'planningRecords/approvePlanningRecord',
    (planningRecord: PlanningRecord) => ({
      payload: planningRecord,
    }),
  )
  discardPlanningRecord = createAction(
    'planningRecords/discardPlanningRecord',
    (planningRecord: PlanningRecord) => ({
      payload: planningRecord,
    }),
  )
}

export default new PlanningRecordsActions()
