import {
  doc,
  setDoc,
  WriteBatch,
  Transaction,
  serverTimestamp,
  getDocFromServer,
  DocumentSnapshot,
  query,
  where,
  and,
} from 'firebase/firestore'
import firestore from '../../firestore'
import { TemplateReview } from '../../models/TemplateReview'
import { TemplatePendingReview } from '../../models/TemplatePendingReview'
import config from '../../config'
import BaseService from '../BaseService'
import { ISODateString } from '../../models/ISODateString'

class TemplateReviewService extends BaseService {
  private createTemplateReviewId = ({
    templateId,
    templateVersion,
  }: {
    templateId: string
    templateVersion: number
  }) => {
    return `${templateId}_${templateVersion}`
  }

  fetchTemplateReview = async ({
    companyId,
    templateId,
    templateVersion,
    transaction,
  }: {
    transaction?: Transaction
    companyId: string
    templateId: string
    templateVersion: number
  }): Promise<TemplateReview> => {
    const templateReviewRef = doc(
      firestore.templateReviews(companyId),
      this.createTemplateReviewId({ templateId, templateVersion }),
    )
    let templateReviewDoc: DocumentSnapshot
    if (transaction) {
      templateReviewDoc = await transaction.get(templateReviewRef)
    } else {
      templateReviewDoc = await getDocFromServer(templateReviewRef)
    }
    if (!templateReviewDoc.exists()) {
      throw new Error("TemplateReview doesn't exist")
    }
    return templateReviewDoc.data() as TemplateReview
  }

  createTemplateReview = ({
    transactionOrBatch,
    companyId,
    templateId,
    templateVersion,
    reviewedByUserId,
    descision,
    feedback,
  }: {
    transactionOrBatch?: WriteBatch | Transaction
    companyId: string
    templateId: string
    templateVersion: number
    reviewedByUserId: string
    descision: TemplateReview['descision']
    feedback: string | null
  }): TemplateReview | Promise<TemplateReview> => {
    const templateReviewsRef = firestore.templateReviews(companyId)
    const id = this.createTemplateReviewId({ templateId, templateVersion })
    const templateReviewRef = doc(templateReviewsRef, id)
    const encodedUrlPart = encodeURIComponent(`/${companyId}/${templateId}_${templateVersion}.pdf`)

    const templateUrl = `${config.bucketBaseUrl}templates${encodedUrlPart}?alt=media`
    const completeTemplateReview: TemplateReview = {
      id: templateReviewRef.id,
      companyId,
      reviewedByUserId,
      templateUrl,
      //@ts-expect-error serverTimestamp isnt the data type we use in the app
      reviewedTimestamp: serverTimestamp(),
      descision,
      feedback,
    }
    if (transactionOrBatch) {
      // todo: for some reason I cant do this without type checking despite both transaction and writeBatch having the same function signature for update
      if (transactionOrBatch instanceof Transaction) {
        transactionOrBatch.set(templateReviewRef, completeTemplateReview, { merge: false })
      }
      if (transactionOrBatch instanceof WriteBatch) {
        transactionOrBatch.set(templateReviewRef, completeTemplateReview, { merge: false })
      }
      return {
        ...completeTemplateReview,
        reviewedTimestamp: new Date().toISOString() as ISODateString,
      }
    }
    return setDoc(templateReviewRef, completeTemplateReview, { merge: false }).then(() => {
      return {
        ...completeTemplateReview,
        reviewedTimestamp: new Date().toISOString() as ISODateString,
      }
    })
  }

  fetchTemplatesPendingReview = async ({
    onReviewRequested,
    onReviewCompleted,
  }: {
    onReviewRequested: (templatePendingReview: TemplatePendingReview) => void
    onReviewCompleted: (templatePendingReview: TemplatePendingReview) => void
  }) => {
    const { data, unsubscribe } = await this.onSnapshot({
      query: query(
        firestore.templatesPendingReview(),
        and(
          where('pendingReviewTemplateId', '!=', null),
          where('pendingReviewTemplateFeedback', '==', null),
        ),
      ),
      onAdded: onReviewRequested,
      onRemoved: onReviewCompleted,
    })
    return {
      data,
      unsubscribe,
    }
  }
}

export default new TemplateReviewService()
