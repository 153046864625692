import { ReactElement } from 'react'
import { useSelector } from '../../../redux/hooks'
import authSelectors from '../../../redux/modules/auth/selectors'

const SuperAdminSwitchRoute = ({
  superAdminPage,
  regularPage,
}: {
  superAdminPage: ReactElement
  regularPage: ReactElement
}) => {
  const role = useSelector(authSelectors.getRole)
  if (!role) {
    return null
  }
  return role === 'super-admin' ? superAdminPage : regularPage
}

export default SuperAdminSwitchRoute
