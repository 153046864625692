import {
  query,
  limit,
  startAfter,
  orderBy,
  QueryDocumentSnapshot,
  DocumentData,
  WriteBatch,
  Transaction,
  UpdateData,
  doc,
  setDoc,
  where,
  increment,
} from 'firebase/firestore'
import firestore from '../../firestore'
import { DeadLetterEvent } from '../../models/DeadLetterEvent'
import BaseService from '../BaseService'
import { PendingDeadLetterCounter } from '../../models/PendingDeadLetterCounter'

class DeadLetterEventService extends BaseService {
  readonly pageSize = 3
  private lastPaginationDoc: QueryDocumentSnapshot<DeadLetterEvent, DocumentData> | undefined

  fetchPaginatedDeadLetterEvents = async ({
    forceFirstPage = false,
    onDeadLetterEventUpdated,
  }: {
    forceFirstPage?: boolean
    onDeadLetterEventUpdated: (template: DeadLetterEvent) => void
  }) => {
    if (!this.lastPaginationDoc || forceFirstPage) {
      return this.fetchDeadLetterEventsFirstPage({
        onDeadLetterEventUpdated,
      })
    }
    return this.fetchDeadLetterEventsNextPage({
      onDeadLetterEventUpdated,
    })
  }

  private fetchDeadLetterEventsFirstPage = async ({
    onDeadLetterEventUpdated,
  }: {
    onDeadLetterEventUpdated: (template: DeadLetterEvent) => void
  }) => {
    const { docs, data, unsubscribe } = await this.onSnapshot({
      query: query(
        firestore.deadLetterEvents(),
        where('status', '==', <DeadLetterEvent['status']>'pending'),
        orderBy('createdTimestamp', 'desc'),
        limit(this.pageSize),
      ),
      onUpdated: onDeadLetterEventUpdated,
    })
    this.lastPaginationDoc = docs?.[docs?.length - 1]
    return {
      data,
      unsubscribe,
    }
  }

  private fetchDeadLetterEventsNextPage = async ({
    onDeadLetterEventUpdated,
  }: {
    onDeadLetterEventUpdated: (template: DeadLetterEvent) => void
  }) => {
    const { docs, data, unsubscribe } = await this.onSnapshot({
      query: query(
        firestore.deadLetterEvents(),
        where('status', '==', <DeadLetterEvent['status']>'pending'),
        orderBy('createdTimestamp', 'desc'),
        startAfter(this.lastPaginationDoc),
        limit(this.pageSize),
      ),
      onUpdated: onDeadLetterEventUpdated,
    })
    this.lastPaginationDoc = docs?.[docs?.length - 1] || this.lastPaginationDoc
    return {
      data,
      unsubscribe,
    }
  }

  retryDeadLetterEvent = ({
    transactionOrBatch,
    deadLetterEventId,
  }: {
    transactionOrBatch?: WriteBatch | Transaction
    deadLetterEventId: string
  }): void | Promise<void> => {
    const deadLetterEventsRef = firestore.deadLetterEvents()
    const deadLetterEventRef = doc(deadLetterEventsRef, deadLetterEventId)
    const deadLetterEventUpdate: UpdateData<DeadLetterEvent> = {
      status: 'retried',
    }
    if (transactionOrBatch) {
      // todo: for some reason I cant do this without type checking despite both transaction and writeBatch having the same function signature for update
      if (transactionOrBatch instanceof Transaction) {
        // @ts-expect-error too complex
        transactionOrBatch.update(deadLetterEventRef, deadLetterEventUpdate)
      }
      if (transactionOrBatch instanceof WriteBatch) {
        transactionOrBatch.update(deadLetterEventRef, deadLetterEventUpdate)
      }
      return
    }
    return setDoc(deadLetterEventRef, deadLetterEventUpdate, { merge: true })
  }

  discardDeadLetterEvent = ({
    transactionOrBatch,
    deadLetterEventId,
  }: {
    transactionOrBatch?: WriteBatch | Transaction
    deadLetterEventId: string
  }): void | Promise<void> => {
    const deadLetterEventsRef = firestore.deadLetterEvents()
    const deadLetterEventRef = doc(deadLetterEventsRef, deadLetterEventId)
    const deadLetterEventUpdate: UpdateData<DeadLetterEvent> = {
      status: 'discarded',
    }
    if (transactionOrBatch) {
      // todo: for some reason I cant do this without type checking despite both transaction and writeBatch having the same function signature for update
      if (transactionOrBatch instanceof Transaction) {
        transactionOrBatch.update(deadLetterEventRef, deadLetterEventUpdate)
      }
      if (transactionOrBatch instanceof WriteBatch) {
        transactionOrBatch.update(deadLetterEventRef, deadLetterEventUpdate)
      }
      return
    }
    return setDoc(deadLetterEventRef, deadLetterEventUpdate, { merge: true })
  }

  decrementPendingDeadLetterCounter = ({
    transactionOrBatch,
    topic,
  }: {
    transactionOrBatch?: WriteBatch | Transaction
    topic: DeadLetterEvent['event']['topic']
  }): void | Promise<void> => {
    const pendingDeadLetterCounterRef = firestore.pendingDeadLetterCounter()
    const reportUpdate: UpdateData<PendingDeadLetterCounter> = {
      [topic]: increment(-1),
    }
    if (transactionOrBatch) {
      // todo: for some reason I cant do this without type checking despite both transaction and writeBatch having the same function signature for update
      if (transactionOrBatch instanceof Transaction) {
        transactionOrBatch.set(pendingDeadLetterCounterRef, reportUpdate, {
          merge: true,
        })
      }
      if (transactionOrBatch instanceof WriteBatch) {
        transactionOrBatch.set(pendingDeadLetterCounterRef, reportUpdate, {
          merge: true,
        })
      }
      return
    }
    return setDoc(pendingDeadLetterCounterRef, reportUpdate, { merge: true }).then(() => {
      return
    })
  }
}

export default new DeadLetterEventService()
