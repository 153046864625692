import {
  query,
  getDocs,
  limit,
  startAfter,
  orderBy,
  QueryDocumentSnapshot,
  DocumentData,
} from 'firebase/firestore'
import firestore from '../../firestore'
import { CompanyPlanningRecord } from '../../models/CompanyPlanningRecord'
import BaseService from '../BaseService'

class CompanyPlanningRecordService extends BaseService {
  readonly pageSize = 3
  private lastPaginationDoc: QueryDocumentSnapshot<CompanyPlanningRecord, DocumentData> | undefined
  fetchCompanyPlanningRecords = async ({
    companyId,
  }: {
    companyId: string
  }): Promise<CompanyPlanningRecord[]> => {
    const companyPlanningRecordsSnapshot = await getDocs(
      firestore.companyPlanningRecords(companyId),
    )
    return companyPlanningRecordsSnapshot.docs.map(companyPlanningRecordDoc =>
      companyPlanningRecordDoc.data(),
    )
  }

  fetchPaginatedCompanyPlanningRecords = async ({
    companyId,
    forceFirstPage = false,
    onCompanyPlanningRecordUpdated,
  }: {
    companyId: string
    forceFirstPage?: boolean
    onCompanyPlanningRecordUpdated: (template: CompanyPlanningRecord) => void
  }) => {
    if (!this.lastPaginationDoc || forceFirstPage) {
      return this.fetchCompanyPlanningRecordsFirstPage({
        companyId,
        onCompanyPlanningRecordUpdated,
      })
    }
    return this.fetchCompanyPlanningRecordsNextPage({
      companyId,
      onCompanyPlanningRecordUpdated,
    })
  }

  private fetchCompanyPlanningRecordsFirstPage = async ({
    companyId,
    onCompanyPlanningRecordUpdated,
  }: {
    companyId: string
    onCompanyPlanningRecordUpdated: (template: CompanyPlanningRecord) => void
  }) => {
    const { docs, data, unsubscribe } = await this.onSnapshot({
      query: query(
        firestore.companyPlanningRecords(companyId),
        orderBy('processedTimestamp', 'desc'),
        limit(this.pageSize),
      ),
      onUpdated: onCompanyPlanningRecordUpdated,
    })
    this.lastPaginationDoc = docs?.[docs?.length - 1]
    return {
      data,
      unsubscribe,
    }
  }

  private fetchCompanyPlanningRecordsNextPage = async ({
    companyId,
    onCompanyPlanningRecordUpdated,
  }: {
    companyId: string
    onCompanyPlanningRecordUpdated: (template: CompanyPlanningRecord) => void
  }) => {
    const { docs, data, unsubscribe } = await this.onSnapshot({
      query: query(
        firestore.companyPlanningRecords(companyId),
        orderBy('processedTimestamp', 'desc'),
        startAfter(this.lastPaginationDoc),
        limit(this.pageSize),
      ),
      onUpdated: onCompanyPlanningRecordUpdated,
    })
    this.lastPaginationDoc = docs?.[docs?.length - 1] || this.lastPaginationDoc
    return {
      data,
      unsubscribe,
    }
  }
}

export default new CompanyPlanningRecordService()
