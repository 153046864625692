import { Suspense, lazy } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import SuspensePanel from './components/atoms/SuspensePanel'
import PrivateRoute from './components/routeSecurity/PrivateRoute'
import PublicRoute from './components/routeSecurity/PublicRoute'
import AwaitEmailVerificationRoute from './components/routeSecurity/AwaitEmailVerificationRoute'
import HeaderLayout from './components/routeLayouts/HeaderLayout'
import { useSubscribeToCompany } from './hooks/company/useSubscribeToCompany'
import { useSubscribeToAuthState } from './hooks/auth/useSubscribeToAuthState'
import { useRefreshAuthTokenOnClaimsChange } from './hooks/auth/useRefreshAuthTokenOnClaimsChange'
import SuperAdminSwitchRoute from './components/routeSecurity/SuperAdminSwitchRoute'

const LoginPage = lazy(() => import('./components/pages/LoginPage'))
const TokenLoginPage = lazy(() => import('./components/pages/TokenLoginPage'))
const RegisterPage = lazy(() => import('./components/pages/RegisterPage'))
const DashboardPage = lazy(() => import('./components/pages/DashboardPage'))
const AdminDashboardPage = lazy(() => import('./components/pages/AdminDashboardPage'))
const TemplatePage = lazy(() => import('./components/pages/TemplatePage'))
const DeadLetterEventsPage = lazy(() => import('./components/pages/DeadLetterEventsPage'))
const VerifyPage = lazy(() => import('./components/pages/VerifyPage'))
const WelcomePage = lazy(() => import('./components/pages/WelcomePage'))
const ResetPasswordSuccessPage = lazy(() => import('./components/pages/ResetPasswordSuccessPage'))
const RequestPasswordResetPage = lazy(() => import('./components/pages/RequestPasswordResetPage'))
const SubscriptionPage = lazy(() => import('./components/pages/SubscriptionPage'))
const AwaitEmailVerificationPage = lazy(
  () => import('./components/pages/AwaitEmailVerificationPage'),
)
const NoMatchPage = lazy(() => import('./components/pages/NoMatchPage'))

function App() {
  useSubscribeToAuthState()
  useRefreshAuthTokenOnClaimsChange()
  useSubscribeToCompany()
  return (
    <Router>
      <Suspense fallback={<SuspensePanel />}>
        <Routes>
          <Route element={<HeaderLayout />}>
            <Route
              path="/"
              element={
                <PrivateRoute allowedRoles={['user', 'owner', 'super-admin']}>
                  <SuperAdminSwitchRoute
                    superAdminPage={<AdminDashboardPage />}
                    regularPage={<DashboardPage />}
                  />
                </PrivateRoute>
              }
            />
            <Route
              path="/verify"
              element={
                <PublicRoute>
                  <VerifyPage />
                </PublicRoute>
              }
            />
            <Route
              path="/welcome"
              element={
                <PublicRoute>
                  <WelcomePage />
                </PublicRoute>
              }
            />
            <Route
              path="/reset-password-success"
              element={
                <PublicRoute>
                  <ResetPasswordSuccessPage />
                </PublicRoute>
              }
            />
            <Route
              path="/reset-password"
              element={
                <PublicRoute>
                  <RequestPasswordResetPage />
                </PublicRoute>
              }
            />
            <Route
              path="/login"
              element={
                <PublicRoute>
                  <LoginPage />
                </PublicRoute>
              }
            />
            <Route
              path="/token-login"
              element={
                <PublicRoute>
                  <TokenLoginPage />
                </PublicRoute>
              }
            />
            <Route
              path="/register"
              element={
                <PublicRoute>
                  <RegisterPage />
                </PublicRoute>
              }
            />
          </Route>
          <Route
            path="/retries"
            element={
              <PrivateRoute allowedRoles={['super-admin']}>
                <DeadLetterEventsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/subscription"
            element={
              <PrivateRoute allowedRoles={['user', 'owner', 'super-admin']}>
                <SubscriptionPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/template/new"
            element={
              <PrivateRoute allowedRoles={['user', 'owner', 'super-admin']}>
                <TemplatePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/template/:templateId"
            element={
              <PrivateRoute allowedRoles={['user', 'owner', 'super-admin']}>
                <TemplatePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/await-email-verification"
            element={
              <AwaitEmailVerificationRoute>
                <AwaitEmailVerificationPage />
              </AwaitEmailVerificationRoute>
            }
          />
          <Route path="*" element={<NoMatchPage />} />
        </Routes>
      </Suspense>
    </Router>
  )
}

export default App
