import { createAction } from '@reduxjs/toolkit'

class OnboardingActions {
  setOnboardingStep = createAction(
    'onboarding/setOnboardingStep',
    (onboardingStep: 'coverage' | 'planning-categories' | 'template' | 'subscribe' | null) => ({
      payload: onboardingStep,
    }),
  )
}

export default new OnboardingActions()
