import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../types/RootState'

class AuthSelectors {
  getUser = (state: RootState) => state.auth.user
  getCompanyId = createSelector([this.getUser], user => {
    return user?.companyId || null
  })
  getUserId = createSelector([this.getUser], user => {
    return user?.id || null
  })
  getEmail = createSelector([this.getUser], user => {
    return user?.email || null
  })
  getLoggedIn = createSelector([this.getUser], user => {
    return !!user
  })
  getEmailVerified = createSelector([this.getUser], user => {
    return !!user?.emailVerified
  })
  getRole = createSelector([this.getUser], user => {
    return user?.role || null
  })
}

export default new AuthSelectors()
