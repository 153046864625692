import {
  useMemo,
  useContext,
  createContext,
  useCallback,
  useState,
  useEffect,
  SyntheticEvent,
} from 'react'
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar'
import Alert, { AlertColor } from '@mui/material/Alert'

const SnackbarContext = createContext<{
  showError: (errorMessage: string) => void
  showSuccess: (successMessage: string) => void
} | null>(null)

const useSnackbar = () => {
  const context = useContext(SnackbarContext)
  if (!context) {
    throw new Error('no SnackbarProvider found when calling useSnackbar()')
  }
  return context
}

export interface SnackbarMessage {
  message: string
  key: number
  severity: AlertColor
}

const SnackbarProvider = ({ children }) => {
  const [snackPack, setSnackPack] = useState<readonly SnackbarMessage[]>([])
  const [open, setOpen] = useState(false)
  const [messageInfo, setMessageInfo] = useState<SnackbarMessage | undefined>(undefined)
  const showError = useCallback((errorMessage: string) => {
    setSnackPack(prev => [
      ...prev,
      { message: errorMessage, severity: 'error', key: new Date().getTime() },
    ])
  }, [])
  const showSuccess = useCallback((successMessage: string) => {
    setSnackPack(prev => [
      ...prev,
      { message: successMessage, severity: 'success', key: new Date().getTime() },
    ])
  }, [])
  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] })
      setSnackPack(prev => prev.slice(1))
      setOpen(true)
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false)
    }
  }, [snackPack, messageInfo, open])

  const handleClose = useCallback((event: SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }, [])

  const handleExited = useCallback(() => {
    setMessageInfo(undefined)
  }, [])
  const value = useMemo(
    () => ({
      showError,
      showSuccess,
    }),
    [showError, showSuccess],
  )
  return (
    <SnackbarContext.Provider value={value}>
      {children}
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        TransitionProps={{ onExited: handleExited }}
        // message={messageInfo ? messageInfo.message : undefined}
      >
        <Alert severity={messageInfo?.severity} variant="filled">
          {messageInfo?.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  )
}

export { useSnackbar, SnackbarProvider }
export default SnackbarProvider
