import { useEffect } from 'react'
import { getAuth } from 'firebase/auth'
import { useDispatch } from '../../redux/hooks'
import authActions from '../../redux/modules/auth/actions'

export const useSubscribeToAuthState = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    getAuth().onAuthStateChanged(currentUser => {
      if (!currentUser) {
        dispatch(authActions.loggedOut())
      }
    })
  }, [dispatch])
}
