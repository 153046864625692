import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import dashboardService from '../../../services/dashboardService'
import { PeriodReport } from '../../../models/PeriodReport'

class DasboardActions {
  loadDashboard = createAsyncThunk(
    'dashboard/loadDashboard',
    async ({ companyId }: { companyId: string }, { dispatch }) => {
      const response = await dashboardService.fetchCompanyDashboardData({
        companyId,
        onReportingRecordAdded: ({ allTime, lastMonth, thisMonth, today, yesterday }) => {
          if (allTime) {
            dispatch(this.updateAllTimePeriodReport(allTime))
            return
          }
          if (lastMonth) {
            dispatch(this.updateLastMonthPeriodReport(lastMonth))
            return
          }
          if (thisMonth) {
            dispatch(this.updateThisMonthPeriodReport(thisMonth))
            return
          }
          if (today) {
            dispatch(this.updateTodayPeriodReport(today))
            return
          }
          if (yesterday) {
            dispatch(this.updateYesterdayPeriodReport(yesterday))
            return
          }
        },
        onReportingRecordUpdated: ({ allTime, lastMonth, thisMonth, today, yesterday }) => {
          if (allTime) {
            dispatch(this.updateAllTimePeriodReport(allTime))
            return
          }
          if (lastMonth) {
            dispatch(this.updateLastMonthPeriodReport(lastMonth))
            return
          }
          if (thisMonth) {
            dispatch(this.updateThisMonthPeriodReport(thisMonth))
            return
          }
          if (today) {
            dispatch(this.updateTodayPeriodReport(today))
            return
          }
          if (yesterday) {
            dispatch(this.updateYesterdayPeriodReport(yesterday))
            return
          }
        },
      })
      return response
    },
  )

  updateAllTimePeriodReport = createAction(
    'dashboard/updateAllTimePeriodReport',
    (periodReport: PeriodReport) => ({
      payload: periodReport,
    }),
  )
  updateLastMonthPeriodReport = createAction(
    'dashboard/updateLastMonthPeriodReport',
    (periodReport: PeriodReport) => ({
      payload: periodReport,
    }),
  )
  updateThisMonthPeriodReport = createAction(
    'dashboard/updateThisMonthPeriodReport',
    (periodReport: PeriodReport) => ({
      payload: periodReport,
    }),
  )
  updateTodayPeriodReport = createAction(
    'dashboard/updateTodayPeriodReport',
    (periodReport: PeriodReport) => ({
      payload: periodReport,
    }),
  )
  updateYesterdayPeriodReport = createAction(
    'dashboard/updateYesterdayPeriodReport',
    (periodReport: PeriodReport) => ({
      payload: periodReport,
    }),
  )
}

export default new DasboardActions()
