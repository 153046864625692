import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import deadLetterEventService from '../../../services/deadLetterEventService'
import { DeadLetterEvent } from '../../../models/DeadLetterEvent'

class DeadLetterEventsActions {
  loadDeadLetterEventsFresh = createAsyncThunk(
    'deadLetterEvents/loadDeadLetterEventsFresh',
    async (params, { dispatch }) => {
      const response = await deadLetterEventService.fetchPaginatedDeadLetterEvents({
        forceFirstPage: true,
        onDeadLetterEventUpdated: deadLetterEvent => {
          dispatch(this.updateDeadLetterEvent(deadLetterEvent))
        },
      })
      return response
    },
  )
  loadDeadLetterEventsNext = createAsyncThunk(
    'deadLetterEvents/loadDeadLetterEventsNext',
    async (params, { dispatch }) => {
      const response = await deadLetterEventService.fetchPaginatedDeadLetterEvents({
        onDeadLetterEventUpdated: deadLetterEvent => {
          dispatch(this.updateDeadLetterEvent(deadLetterEvent))
        },
      })
      return response
    },
  )

  updateDeadLetterEvent = createAction(
    'deadLetterEvents/updateDeadLetterEvent',
    (deadLetterEvent: DeadLetterEvent) => ({
      payload: deadLetterEvent,
    }),
  )

  retryDeadLetterEvent = createAction(
    'deadLetterEvents/retryDeadLetterEvent',
    (deadLetterEvent: DeadLetterEvent) => ({
      payload: deadLetterEvent,
    }),
  )
  discardDeadLetterEvent = createAction(
    'deadLetterEvents/discardDeadLetterEvent',
    (deadLetterEvent: DeadLetterEvent) => ({
      payload: deadLetterEvent,
    }),
  )
}

export default new DeadLetterEventsActions()
