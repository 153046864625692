import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import companyPlanningRecordService from '../../../services/companyPlanningRecordService'
import { CompanyPlanningRecord } from '../../../models/CompanyPlanningRecord'

class CompanyPlanningRecordsActions {
  loadCompanyPlanningRecordsFresh = createAsyncThunk(
    'companyPlanningRecords/loadCompanyPlanningRecordsFresh',
    async ({ companyId }: { companyId: string }, { dispatch }) => {
      const response = await companyPlanningRecordService.fetchPaginatedCompanyPlanningRecords({
        companyId,
        forceFirstPage: true,
        onCompanyPlanningRecordUpdated: companyPlanningRecord => {
          dispatch(this.updateCompanyPlanningRecord(companyPlanningRecord))
        },
      })
      return response
    },
  )
  loadCompanyPlanningRecordsNext = createAsyncThunk(
    'companyPlanningRecords/loadCompanyPlanningRecordsNext',
    async ({ companyId }: { companyId: string }, { dispatch }) => {
      const response = await companyPlanningRecordService.fetchPaginatedCompanyPlanningRecords({
        companyId,
        onCompanyPlanningRecordUpdated: companyPlanningRecord => {
          dispatch(this.updateCompanyPlanningRecord(companyPlanningRecord))
        },
      })
      return response
    },
  )

  updateCompanyPlanningRecord = createAction(
    'companyPlanningRecords/updateCompanyPlanningRecord',
    (companyPlanningRecord: CompanyPlanningRecord) => ({
      payload: companyPlanningRecord,
    }),
  )
}

export default new CompanyPlanningRecordsActions()
