import { useEffect, ReactElement, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import authSelectors from '../../../redux/modules/auth/selectors'
import { store } from '../../../redux/store'
import { Role } from '../../../models/Role'

const PrivateRoute = ({
  children,
  allowedRoles = [],
}: {
  children: ReactElement
  allowedRoles: Role[]
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const loggedIn = useMemo(() => {
    return authSelectors.getLoggedIn(store.getState())
  }, [])
  const emailVerified = useMemo(() => {
    return authSelectors.getEmailVerified(store.getState())
  }, [])
  const role = useMemo(() => {
    return authSelectors.getRole(store.getState())
  }, [])
  useEffect(() => {
    if (!loggedIn) {
      console.info('>>> PrivateRoute redirect to /login')
      navigate('/login', {
        state: { from: location.state?.from || location.pathname },
        replace: true,
      })
      return
    }
    if (!emailVerified) {
      console.info('>>> PrivateRoute redirect to /await-email-verification')
      navigate('/await-email-verification', {
        state: {
          resendEmailPromptDelayed: location.state?.resendEmailPromptDelayed,
          from: location.state?.from || location.pathname,
        },
        replace: true,
      })
      return
    }
    if (allowedRoles.length && role && !allowedRoles.includes(role)) {
      console.info('>>> PrivateRoute redirect to /access-denied')
      navigate('/access-denied', {
        state: { from: location.state?.from || location.pathname },
        replace: true,
      })
      return
    }
  }, [
    allowedRoles,
    emailVerified,
    location.pathname,
    location.state?.from,
    location.state?.resendEmailPromptDelayed,
    loggedIn,
    navigate,
    role,
  ])
  return children
}

export default PrivateRoute
