import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import dashboardService from '../../../services/dashboardService'
import { PeriodReport } from '../../../models/PeriodReport'
import { CompanyRankReport } from '../../../models/CompanyRankReport'
import { CompanyReportInfo } from '../../../models/CompanyReportInfo'
import { PendingDeadLetterCounter } from '../../../models/PendingDeadLetterCounter'

class AdminDashboardActions {
  loadDashboard = createAsyncThunk('adminDashboard/loadDashboard', async (params, { dispatch }) => {
    const dashboardDataPromise = dashboardService.fetchSuperAdminDashboardData({
      onReportingRecordAdded: ({ allTime, lastMonth, thisMonth, today, yesterday }) => {
        if (allTime) {
          dispatch(this.updateAllTimePeriodReport(allTime))
          return
        }
        if (lastMonth) {
          dispatch(this.updateLastMonthPeriodReport(lastMonth))
          return
        }
        if (thisMonth) {
          dispatch(this.updateThisMonthPeriodReport(thisMonth))
          return
        }
        if (today) {
          dispatch(this.updateTodayPeriodReport(today))
          return
        }
        if (yesterday) {
          dispatch(this.updateYesterdayPeriodReport(yesterday))
          return
        }
      },
      onReportingRecordUpdated: ({ allTime, lastMonth, thisMonth, today, yesterday }) => {
        if (allTime) {
          dispatch(this.updateAllTimePeriodReport(allTime))
          return
        }
        if (lastMonth) {
          dispatch(this.updateLastMonthPeriodReport(lastMonth))
          return
        }
        if (thisMonth) {
          dispatch(this.updateThisMonthPeriodReport(thisMonth))
          return
        }
        if (today) {
          dispatch(this.updateTodayPeriodReport(today))
          return
        }
        if (yesterday) {
          dispatch(this.updateYesterdayPeriodReport(yesterday))
          return
        }
      },
    })
    const companyRankingDataPromise = dashboardService.fetchSuperAdminCustomerRankingData({
      onReportingRecordAdded: ({ allTime, lastMonth, thisMonth }) => {
        if (allTime) {
          dispatch(this.updateAllTimeCompanyRankReport(allTime))
          return
        }
        if (lastMonth) {
          dispatch(this.updateLastMonthCompanyRankReport(lastMonth))
          return
        }
        if (thisMonth) {
          dispatch(this.updateThisMonthCompanyRankReport(thisMonth))
          return
        }
      },
      onReportingRecordUpdated: ({ allTime, lastMonth, thisMonth }) => {
        if (allTime) {
          dispatch(this.updateAllTimeCompanyRankReport(allTime))
          return
        }
        if (lastMonth) {
          dispatch(this.updateLastMonthCompanyRankReport(lastMonth))
          return
        }
        if (thisMonth) {
          dispatch(this.updateThisMonthCompanyRankReport(thisMonth))
          return
        }
      },
    })

    const companyInfoDataPromise = dashboardService.fetchSuperAdminCompanyReportInfo({
      onCompanyReportInfoUpdated: (company: CompanyReportInfo) => {
        dispatch(this.updateCompanyReportInfo(company))
      },
    })
    const pendingDeadLetterCounterPromise = dashboardService.fetchPendingDeadLetterCounter({
      onPendingDeadLetterCounterUpdated: (pendingDeadLetterCounter: PendingDeadLetterCounter) => {
        dispatch(this.updatePendingDeadLetterCounter(pendingDeadLetterCounter))
      },
    })

    const data = Promise.allSettled([
      dashboardDataPromise,
      companyRankingDataPromise,
      companyInfoDataPromise,
      pendingDeadLetterCounterPromise,
    ]).then(
      ([
        dashboardDataResponse,
        companyRankingDataResponse,
        companyInfoDataResponse,
        pendingDeadLetterCounterResponse,
      ]) => {
        const failed =
          dashboardDataResponse.status === 'rejected' ||
          companyRankingDataResponse.status === 'rejected' ||
          companyInfoDataResponse.status === 'rejected' ||
          pendingDeadLetterCounterResponse.status === 'rejected'

        if (failed && dashboardDataResponse.status === 'fulfilled') {
          dashboardDataResponse.value.unsubscribe()
        }
        if (failed && companyRankingDataResponse.status === 'fulfilled') {
          companyRankingDataResponse.value.unsubscribe()
        }
        if (failed && companyInfoDataResponse.status === 'fulfilled') {
          companyInfoDataResponse.value.unsubscribe()
        }
        if (failed && pendingDeadLetterCounterResponse.status === 'fulfilled') {
          pendingDeadLetterCounterResponse.value.unsubscribe()
        }
        if (failed) {
          throw new Error('Failed to load dashboard data')
        }
        return {
          dashboardData: dashboardDataResponse.value,
          companyRankingData: companyRankingDataResponse.value,
          companyInfoData: companyInfoDataResponse.value,
          pendingDeadLetterCounterData: pendingDeadLetterCounterResponse.value,
          unsubscribe: () => {
            dashboardDataResponse.value.unsubscribe()
            companyRankingDataResponse.value.unsubscribe()
            companyInfoDataResponse.value.unsubscribe()
            pendingDeadLetterCounterResponse.value.unsubscribe()
          },
        }
      },
    )
    return data
  })

  updateAllTimePeriodReport = createAction(
    'adminDashboard/updateAllTimePeriodReport',
    (periodReport: PeriodReport) => ({
      payload: periodReport,
    }),
  )
  updateLastMonthPeriodReport = createAction(
    'adminDashboard/updateLastMonthPeriodReport',
    (periodReport: PeriodReport) => ({
      payload: periodReport,
    }),
  )
  updateThisMonthPeriodReport = createAction(
    'adminDashboard/updateThisMonthPeriodReport',
    (periodReport: PeriodReport) => ({
      payload: periodReport,
    }),
  )
  updateTodayPeriodReport = createAction(
    'adminDashboard/updateTodayPeriodReport',
    (periodReport: PeriodReport) => ({
      payload: periodReport,
    }),
  )
  updateYesterdayPeriodReport = createAction(
    'adminDashboard/updateYesterdayPeriodReport',
    (periodReport: PeriodReport) => ({
      payload: periodReport,
    }),
  )

  updateAllTimeCompanyRankReport = createAction(
    'adminDashboard/updateAllTimeCompanyRankReport',
    (companyRank: CompanyRankReport) => ({
      payload: companyRank,
    }),
  )
  updateLastMonthCompanyRankReport = createAction(
    'adminDashboard/updateLastMonthCompanyRankReport',
    (companyRank: CompanyRankReport) => ({
      payload: companyRank,
    }),
  )
  updateThisMonthCompanyRankReport = createAction(
    'adminDashboard/updateThisMonthCompanyRankReport',
    (companyRank: CompanyRankReport) => ({
      payload: companyRank,
    }),
  )

  updateCompanyReportInfo = createAction(
    'adminDashboard/updateCompanyReportInfo',
    (companyInfo: CompanyReportInfo) => ({
      payload: companyInfo,
    }),
  )

  updatePendingDeadLetterCounter = createAction(
    'adminDashboard/updatePendingDeadLetterCounter',
    (pendingDeadLetterCounter: PendingDeadLetterCounter) => ({
      payload: pendingDeadLetterCounter,
    }),
  )
}

export default new AdminDashboardActions()
