import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore, REHYDRATE, PERSIST } from 'redux-persist'
import { combineReducers } from 'redux'
import onboardingReducer from './modules/onboarding/reducer'
import authReducer from './modules/auth/reducer'
import authMiddleware from './modules/auth/middleware'
import dashboardReducer from './modules/dashboard/reducer'
import adminDashboardReducer from './modules/adminDashboard/reducer'
import templatesReducer from './modules/templates/reducer'
import templateReducer from './modules/template/reducer'
import companyReducer from './modules/company/reducer'
import companyPlanningRecordsReducer from './modules/companyPlanningRecords/reducer'
import planningRecordsReducer from './modules/planningRecords/reducer'
import deadLetterEventsReducer from './modules/deadLetterEvents/reducer'
import templatesPendingReviewReducer from './modules/templatesPendingReivew/reducer'
import { createMigrate } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import migrations from './migrations'

const reducers = {
  onboarding: onboardingReducer,
  auth: authReducer,
  dashboard: dashboardReducer,
  adminDashboard: adminDashboardReducer,
  templates: templatesReducer,
  template: templateReducer,
  company: companyReducer,
  templatesPendingReview: templatesPendingReviewReducer,
  companyPlanningRecords: companyPlanningRecordsReducer,
  planningRecords: planningRecordsReducer,
  deadLetterEvents: deadLetterEventsReducer,
}

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage,
    migrate: createMigrate(migrations, { debug: false }),
    version: 1,
    blacklist: ['companyPlanningRecords', 'planningRecords', 'deadLetterEvents'],
  },
  combineReducers(reducers),
)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [REHYDRATE, PERSIST],
        ignoredActionPaths: [
          'payload.unsubscribe',
          'payload.dashboardData.unsubscribe',
          'payload.companyRankingData.unsubscribe',
          'payload.companyInfoData.unsubscribe',
          'payload.pendingDeadLetterCounterData.unsubscribe',
        ],
      },
    }).concat([authMiddleware.logoutMiddleware])
  },
})

export const persistor = persistStore(store, {}, () => {
  persistor.persist()
  store.dispatch({ type: 'persist/REHYDRATE_COMPLETE' })
})
