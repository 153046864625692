import { useRef, MutableRefObject } from 'react'
import {
  TypedUseSelectorHook,
  EqualityFn,
  NoInfer,
  // UseSelectorOptions,
  useDispatch as useDispatchBase,
  useSelector as useSelectorBase,
} from 'react-redux'
import type { AppDispatch } from './types/AppDispatch'
import type { RootState } from './types/RootState'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useDispatch = () => useDispatchBase<AppDispatch>()
export const useSelector: TypedUseSelectorHook<RootState> = useSelectorBase

interface TypedUseSelectorHookWithRef<TState> {
  <TSelected>(
    selector: (state: TState) => TSelected,
    equalityFn?: EqualityFn<NoInfer<TSelected>>,
  ): [TSelected, MutableRefObject<TSelected>]
}

export const useSelectorWithRef: TypedUseSelectorHookWithRef<RootState> = (
  selector,
  equalityFn,
) => {
  const value = useSelectorBase(selector, equalityFn)
  const ref = useRef(value)
  ref.current = value
  return [value, ref]
}
