import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import templateService from '../../../services/templateService'
import { Template } from '../../../models/Template'

class TemplateActions {
  loadTemplate = createAsyncThunk(
    'template/loadTemplate',
    async ({ templateId, companyId }: { templateId: string; companyId: string }) => {
      const response = await templateService.fetchTemplate({ templateId, companyId })
      return response
    },
  )

  setTemplateAfterCreate = createAction(
    'template/setTemplateAfterCreate',
    (template: Template) => ({
      payload: template,
    }),
  )

  updateTemplateAfterSave = createAction('template/updateTemplateAfterSave', (version: number) => ({
    payload: version,
  }))
}

export default new TemplateActions()
